<!-- eslint-disable global-require -->
<template>
  <div>
    <b-card
      class="mb-0"
    >
      <b-row class="justify-between">
        <b-col
          cols="12"
          md="6"
        >
          <b-form-group
            id="fieldset-horizontal"
            :label="`${ $t('Departments') }`"
            label-for="Departments"
            label-cols-sm="2"
            label-cols-lg="2"
            content-cols-sm
            content-cols-lg="7"
          >
            <v-select
              v-model="selected"
              :options="Options"
            />
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          md="6"
        >
          <div class="d-flex align-items-center justify-content-end">
            <b-form-input
              v-model="searchQuery"
              class="d-inline-block mr-1"
              placeholder="Search..."
            />
            <b-button
              variant="primary"
              to="add"
            >
              <span class="text-nowrap">Add User</span>
            </b-button>
          </div>
        </b-col>
      </b-row>
    </b-card>
    <!-- table -->
    <vue-good-table
      ref="promotion-products"
      class="custom-good-table-list-user"
      :columns="columns"
      :rows="listUser"
      theme="polar-bear"
      style-class="vgt-table"
      :search-options="{
        enabled: true,
        externalQuery: searchTerm }"
      :pagination-options="{
        enabled: true,
        perPage: pageLength
      }"
    >
      <template
        slot="table-column"
        slot-scope="props"
      >
        <span
          v-if="props.column.label =='Trạng thái' || props.column.label =='Công Cụ'"
          class="ctrl-header-center"
        >
          <i class="fa fa-address-book" /> {{ props.column.label }}
        </span>
        <span v-else>
          {{ props.column.label }}
        </span>
      </template>
      <template
        slot="table-row"
        slot-scope="props"
      >
        <!-- Column: Name -->
        <div
          v-if="props.column.field === 'fullName'"
          class="avatar-name mx-auto"
        >
          <b-avatar
            :src="props.row.img"
            size="lg"
            class="mr-1"
          />
          <div class="name-block">
            <router-link
              to="edit"
              class="name"
            >
              {{ props.row.name }} 11
            </router-link>
            <div class="des">
              {{ props.row.des }}
            </div>
          </div>
        </div>

        <!-- Column: Discount Price -->
        <div
          v-else-if="props.column.field === 'email'"
        >
          {{ props.row.email }}
        </div>

        <!-- Column: Discount Price -->
        <div
          v-else-if="props.column.field === 'phone'"
        >
          {{ props.row.phone }}
        </div>

        <!-- Column: Product On Sale -->
        <div v-else-if="props.column.field === 'departments'">
          <div class="text-nowrap">
            <feather-icon
              :icon="resolveUserRoleIcon(props.row.role)"
              size="18"
              class="mr-50"
              :class="`text-${resolveUserRoleVariant(props.row.role)}`"
            />
            <span class="align-text-top text-capitalize">{{ props.row.role }}</span>
          </div>
        </div>

        <!-- Column: Status -->
        <div
          v-else-if="props.column.field === 'status'"
          class="text-center"
        >
          <b-badge
            pill
            :variant="`light-${resolveUserStatusVariant(props.row.status)}`"
            class="text-capitalize"
          >
            {{ props.row.status }}
          </b-badge>
        </div>

        <!-- Column: Actions -->
        <div
          v-else-if="props.column.field === 'action'"
          class="text-center"
        >
          <template>
            <b-dropdown
              variant="link"
              toggle-class="p-0"
              no-caret
              :right="$store.state.appConfig.isRTL"
            >

              <template #button-content>
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="align-middle text-body"
                />
              </template>
              <b-dropdown-item>
                <feather-icon icon="FileTextIcon" />
                <span class="align-middle ml-50">Detail</span>
              </b-dropdown-item>
              <b-dropdown-item>
                <feather-icon icon="EditIcon" />
                <span class="align-middle ml-50">Edit</span>
              </b-dropdown-item>
              <b-dropdown-item>
                <feather-icon icon="TrashIcon" />
                <span class="align-middle ml-50">Delete</span>
              </b-dropdown-item>
            </b-dropdown>
          </template>
        </div>
      </template>

      <!-- pagination -->
      <template
        slot="pagination-bottom"
        slot-scope="props"
      >
        <div class="d-flex justify-content-between flex-wrap ctrl-pd">
          <div class="d-flex align-items-center mb-0">
            <span class="text-nowrap ">
              Showing 1 to
            </span>
            <b-form-select
              v-model="pageLength"
              :options="['10','30','50']"
              class="mx-1"
              @input="(value)=>props.perPageChanged({currentPerPage:value})"
            />
            <span class="text-nowrap"> of {{ props.total }} entries </span>
          </div>
          <div>
            <b-pagination
              :value="1"
              :total-rows="props.total"
              :per-page="pageLength"
              first-number
              last-number
              align="right"
              prev-class="prev-item"
              next-class="next-item"
              @input="(value)=>props.pageChanged({currentPage:value})"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </div>
        </div>
      </template>
    </vue-good-table>
  </div>
  <!-- <div v-else>
    <no-data />
  </div> -->
</template>
<script>
import {
  BAvatar,
  BFormSelect,
  BPagination,
  BBadge,
  BDropdownItem,
  BDropdown,
  BRow,
  BCol,
  BCard,
  BFormInput,
  BButton,
  BFormGroup,
} from 'bootstrap-vue'
import general from '@/mixins/index'
import { VueGoodTable } from 'vue-good-table'
import vSelect from 'vue-select'

export default {
  components: {
    VueGoodTable,
    BAvatar,
    BFormSelect,
    BPagination,
    // eslint-disable-next-line vue/no-unused-components
    BBadge,
    BDropdownItem,
    BDropdown,
    BRow,
    BCol,
    BCard,
    BFormInput,
    BButton,
    BFormGroup,
    vSelect,
  },
  mixins: [general],
  data() {
    return {
      searchTerm: '',
      pageLength: 10,
      searchQuery: '',
      selected: '',
      Options: [
        { label: 'Kế toán', value: 'KT' },
        { label: 'Kinh Doanh', value: 'KD' },
        { label: 'Kỹ Thuật', value: 'KyT' },
        { label: 'Nhân Sự', value: 'NS' },
        { label: 'PR', value: 'PR' },
      ],
      columns: [
        {
          label: 'Tài Khoản',
          field: 'fullName',
          tdClass: 'text-left',
        },
        {
          label: 'Email',
          field: 'email',
          tdClass: 'text-left',
        },
        {
          label: 'Điện thoại',
          field: 'phone',
          tdClass: 'text-left',
        },
        {
          label: 'Phân quyền',
          field: 'departments',
        },
        {
          label: 'Trạng thái',
          field: 'status',
        },
        {
          label: 'Công Cụ',
          field: 'action',
        },
      ],
      listUser: [
        {
          img: 'https://beta-app.busodevelopers.com/img/2.748e7504.png',
          name: 'Galen Slixby',
          des: 'mo ta',
          email: 'hredmore1@imgur.com',
          phone: '(479) 232-9151',
          role: 'author',
          status: 'pending',
        },
        {
          img: 'https://beta-app.busodevelopers.com/img/2.748e7504.png',
          name: 'Galen Slixby',
          des: 'mo ta',
          email: 'hredmore1@imgur.com',
          phone: '(479) 232-9151',
          role: 'subscriber',
          status: 'active',
        },
        {
          img: 'https://beta-app.busodevelopers.com/img/2.748e7504.png',
          name: 'Galen Slixby',
          des: 'mo ta',
          email: 'hredmore1@imgur.com',
          phone: '(479) 232-9151',
          role: 'maintainer',
          status: 'inactive',
        },
        {
          img: 'https://beta-app.busodevelopers.com/img/2.748e7504.png',
          name: 'Galen Slixby',
          des: 'mo ta',
          email: 'hredmore1@imgur.com',
          phone: '(479) 232-9151',
          role: 'editor',
          status: 'pending',
        },
        {
          img: 'https://beta-app.busodevelopers.com/img/2.748e7504.png',
          name: 'Galen Slixby',
          des: 'mo ta',
          email: 'hredmore1@imgur.com',
          phone: '(479) 232-9151',
          role: 'admin',
          status: 'active',
        },
      ],
    }
  },
  mounted() {
    // this.loadList()
  },
  methods: {
    resolveUserRoleVariant(role) {
      if (role === 'subscriber') return 'primary'
      if (role === 'author') return 'warning'
      if (role === 'maintainer') return 'success'
      if (role === 'editor') return 'info'
      if (role === 'admin') return 'danger'
      return 'primary'
    },

    resolveUserRoleIcon(role) {
      if (role === 'subscriber') return 'UserIcon'
      if (role === 'author') return 'SettingsIcon'
      if (role === 'maintainer') return 'DatabaseIcon'
      if (role === 'editor') return 'Edit2Icon'
      if (role === 'admin') return 'ServerIcon'
      return 'UserIcon'
    },

    resolveUserStatusVariant(status) {
      if (status === 'pending') return 'warning'
      if (status === 'active') return 'success'
      if (status === 'inactive') return 'secondary'
      return 'primary'
    },
  },
}
</script>

<style lang="scss" scoped>
.avatar-name{
  display: flex;
  font-size: 14px;
  .name{
    font-weight: 600;
  }
}
.ctrl-header-center{
  text-align: center;
  display: block;
}
</style>
<style lang="scss">
.custom-good-table-list-user{
  background-color: #fff;
  .ctrl-pd{
    padding: 16px;
  }
  table.vgt-table{
    font-size: 14px;
  }
  thead{
    background-color: #f3f2f7
  }
  .vgt-left-align{
    vertical-align: middle;
  }
}
</style>
